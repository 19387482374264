
<template>
  <div>
    <CCard accentColor="primary">
      <CCardBody>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <CForm @submit.stop.prevent="handleSubmit(submit)">
            <CRow>
              <CCol md="6">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  name="Jenis Layanan"
                >
                  <div role="group" class="form-group">
                    <label class>Jenis Layanan</label>
                    <v-select
                      v-model="form.ref_jenis_layanan_id"
                      :options="optionsJenisLayanan"
                      label="nama_layanan"
                      :reduce="(jenis_layanan) => jenis_layanan.id"
                      :filterable="false"
                      :class="[
                        { 'is-valid': !errors[0] },
                        { 'is-invalid': errors[0] },
                      ]"
                    ></v-select>
                    <div class="invalid-feedback" v-if="errors[0]">
                      {{ errors[0] }}
                    </div>
                  </div>
                </validation-provider>
              </CCol>
              <CCol md="6">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  name="Dokumen"
                >
                  <div role="group" class="form-group">
                    <label class>Dokumen</label>
                    <v-select
                      v-model="form.id_jenis_dokumen"
                      :options="optionsJenisDokumen"
                      label="nama_jenis_dokumen"
                      :reduce="(dok) => dok.id_jenis_dokumen"
                      :filterable="false"
                      :class="[
                        { 'is-valid': !errors[0] },
                        { 'is-invalid': errors[0] },
                      ]"
                    ></v-select>
                    <div class="invalid-feedback" v-if="errors[0]">
                      {{ errors[0] }}
                    </div>
                  </div>
                </validation-provider>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="6">
                <CInput
                  label="Nama Dokumen"
                  placeholder="Nama Dokumen"
                  v-model="form.nama_dokumen"
                  readonly
                ></CInput>
              </CCol>
              <CCol md="6">
                <CInput
                  label="Format File"
                  placeholder="Format File"
                  v-model="form.format_file"
                ></CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol col="6" class="text-left">
                <CSpinner color="success" size="sm" v-if="loading" />
                <CButton color="primary" class="px-4" type="submit" v-else
                  >Simpan</CButton
                >
              </CCol>
            </CRow>
          </CForm>
        </ValidationObserver>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Swal from "sweetalert2";
import VSelect from "vue-select";
export default {
  name: "FormPersyaratanLayanan",
  props: ["item"],
  components: {
    ValidationProvider,
    ValidationObserver,
    VSelect,
  },
  data() {
    return {
      optionsJenisLayanan: [],
      optionsJenisDokumen: [],
      errorMessage: null,
      form: {
        id_jenis_dokumen: null,
        ref_jenis_layanan_id: null,
        nama_dokumen: null,
        format_file: null,
      },
      loading: false,
    };
  },
  mounted() {},
  watch: {
    item: function (item) {
      if (item) {
        this.form.ref_jenis_layanan_id = item.ref_jenis_layanan_id;
        this.form.id_jenis_dokumen = item.id_jenis_dokumen;
        this.form.nama_dokumen = item.nama_dokumen;
        this.form.format_file = item.format_file;
      }
    },
    "form.id_jenis_dokumen": function (item) {
      if (item) {
        const me = this;
        this.optionsJenisDokumen.forEach((el) => {
          if (el.id_jenis_dokumen == item)
            me.form.nama_dokumen = el.prefix_nama_file;
            return
        });
      }
    },
  },
  methods: {
    async loadDD() {
      this.form.ref_jenis_layanan_id = null;
      this.form.id_jenis_dokumen = null;
      this.form.nama_dokumen = null;
      this.form.format_file = null;
      this.optionsJenisLayanan = await this.$store.dispatch(
        "jenis_layanan/autocomplete2"
      );
      this.optionsJenisDokumen = await this.$store.dispatch(
        "master_jenis_dokumen/autocomplete"
      );
    },
    async submit() {
      const vm = this;
      try {
        this.loading = true;
        let { status } = this.item
          ? await this.$store.dispatch("persyaratan_pelayanan/update", {
              data: this.form,
              id: vm.item.id,
            })
          : await this.$store.dispatch(
              "persyaratan_pelayanan/store",
              this.form
            );
        if (status >= 200 && status <= 202) {
          Swal.fire({
            title: "Sukses",
            text: "Data berhasil tersimpan!",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Tutup!",
          }).then(() => {
            if (!vm.item) {
              vm.form.nama_layanan = null;
              requestAnimationFrame(() => {
                this.$refs.observer.reset();
              });
            }
            vm.$emit("done");
          });
        }
      } catch (x) {
        console.log(x);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
